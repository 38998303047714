.ant-btn {
  //text-transform: uppercase;
  height: 40px;
  letter-spacing: 0.05em;
  font-size: 13px;
  border-radius: 90px;
  transition: none;
  padding: 0 20px;

  &:hover,
  &:focus {
    color: #1138c2;
    border-color: #1138c2;
  }
}

.ant-btn-primary {
  @include btn($k100, $wht);
  border: none;
  min-height: 44px;

  &:hover,
  &:focus {
    color: #fff;
    background: $k80;
    cursor: pointer;
  }
}

.ant-btn-primary {
  .anticon {
    svg {
      color: $wht;
    }
  }
}

.ant-btn-secondary {
  border-radius: 90px;
  font-size: 14px;
  color: lightgray;

  // font-weight: 600;
  .anticon {
    svg {
      color: $k100;
    }
  }
}

.ant-btn-secondary:hover,
.ant-btn-secondary:focus {
  color: $primary-color;
  background: #fff;
  border-color: $primary-color;
}

.ant-btn-danger {
  background-color: $danger;
  color: #fff;
  border-color: transparent;

  &:focus {
    color: #fff;
    background-color: darken($danger, 5);
    border-color: transparent;
  }
}

.ant-btn>.anticon+span,
.ant-btn>span+.anticon {
  margin-left: 0px !important;
}

// ikonice search u headeru tabele
.ant-table-filter-trigger {
  svg {
    fill: #b3b3b3;
  }
}

.buttonCustom {
  background-color: #5A566F !important;
}

.buttonCustom:hover {
  background-color: #908ca6 !important;
}