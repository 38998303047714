.admin_header {
  padding-left: 40px;
  padding-right: 40px;
  margin: 0;
  display: flex;
  align-items: center;
  line-height: 40px;

  >.anticon {
    color: #fff;
    font-size: 30px;
    outline: none;
    margin-right: 20px;

    @include media('<=960px') {
      font-size: 23px;
    }
  }

  .ant-dropdown-link {
    font-size: 16px;
    color: #fff;
    margin-left: 2rem;
    cursor: pointer;
    //margin-right: 30px;

    &:hover {
      text-decoration: none;
    }

    @include media('<=960px') {
      font-size: 12px;
    }

    i {
      vertical-align: baseline;
      margin-left: 6px;
    }
  }

  .ant-select-arrow {
    top: 38%;
  }

  .ant-select-single {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        border: none;
      }
    }
  }
}

.ant-dropdown-trigger>.anticon.anticon-down,
.ant-dropdown-link>.anticon.anticon-down,
.ant-dropdown-button>.anticon.anticon-down {
  font-size: 10px;
  vertical-align: baseline;
  margin-right: 100px;
  margin-left: 5px;
}

.admin_header {
  line-height: 60px;
}

.ant-layout-header span {
  margin-top: 0 !important;
}

.ant-select-item-option-selected {
  &:not(.ant-select-item-option-disabled) {
    background-color: #ffffff;
    font-weight: 400;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}

@media only screen and (max-width: 380px) {
  .admin_header {
    .ant-dropdown-link {
      margin-right: 10px;
    }
  }
}

.logo-mobile {
  display: none;

  @include media("<=lg") {
    display: none;
    width: 100px;
    right: -22px;
    position: relative;
  }
}

.btn-collapse {
  display: none;

  @include media("<=lg") {
    display: block;
    z-index: 1000;
  }
}


@media (max-width: 991.98px) {
  .header {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 62px;
    padding: 0 10px;
    height: 62px;
    display: flex;
    align-items: center;
    background-color: $primary-color;
  }

  .header-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.3);
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }
}

@media (min-width: 992px) {
  .header {
    display: none;
  }
}

.header-overlay.isActive {
  opacity: 1;
  visibility: visible;
}

.desna-strana {
  display: flex;
}

.header .btn-collapse {}


.header .btn-menu {
  position: relative;
  width: 90px !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}